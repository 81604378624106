#header-navbar-nav {
  svg {
    font-size: 1.25em;
  }

  .dropdown-menu {
    svg {
      font-size: 1em;
    }
  }
}
