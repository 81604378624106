@media print {
  @page {
    size: A4 landscape;
  }

  *,
  *:before,
  *:after {
    box-shadow: none !important;
    text-shadow: none !important;
  }

  body {
    width: auto !important;
    font-size: 12px;
    print-color-adjust: exact;
    margin: 35px 35px 35px 35px;
  }

  a,
  a:visited {
    text-decoration: none;
    color: #000;
  }

  thead {
    display: table-header-group; // h5bp.com/t
  }

  p,
  h2 {
    orphans: 3;
    widows: 3;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .navbar {
    display: none;
  }

  .label {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .score-table {
    width: 25%;
  }

  .table-bordered {
    th,
    td {
      border: 1px solid #ddd !important;
    }
  }

  .nav-tabs {
    @extend .d-print-none;
  }

  .page-break-after {
    page-break-after: always;
  }
}
