@import '~bootstrap/scss/functions';

$primary: #337ab7;
$success: #5cb85c;
$info: #5bc0de;
$warning: #f0ad4e;
$danger: #d9534f;
$table-cell-padding-x-sm: 2px;
$table-cell-padding-y-sm: 2px;
$accordion-button-bg: #f8f9fa;
$accordion-button-active-bg: #f8f9fa;
$font-size-base: 0.875rem;

@import '~bootstrap/scss/bootstrap';
@import 'print';

body {
  width: 100%;
  height: 100%;
}

.icon-wrapper {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
}

h2 small {
  font-size: 65%;
  color: #777777;
}

.btn-link,
a {
  text-decoration-line: none;
}

.table {
  @extend .table-sm;

  .group {
    padding: 0;
    margin: 0;

    li {
      list-style-type: none;
    }

    .btn {
      padding: 6px;
    }
  }

  .total th {
    @extend .bg-success-subtle;
  }

  .subtotal th {
    @extend .bg-secondary-subtle;
  }

  .info {
    font-weight: bold;
    margin-bottom: 10px;
    padding-top: 5px;
    padding-bottom: 5px;

    th,
    .col {
      @extend .bg-info-subtle;
    }
  }

  .add {
    margin-bottom: 15px;
  }

  .buttons {
    padding-right: 0;
  }

  .btn-xs {
    margin-left: 1em;
  }

  .assets {
    min-height: 1em;
  }

  a {
    cursor: pointer;
  }
}

tbody.wallet-group tr.subtotal {
  border-bottom: 15px solid white;
}

.CASH,
.CASH th {
  background-color: #d3d3d3 !important;
}

.DEFENSIVE,
.DEFENSIVE th {
  background-color: #00bfff !important;
}

.NEUTRAL,
.NEUTRAL th {
  background-color: #90ee90 !important;
}

.DYNAMIC,
.DYNAMIC th {
  background-color: #ff8080 !important;
}

.profile {
  font-size: x-small;
  padding: 4px;
  margin-right: 4px;
}

.percentage-bar {
  background-color: #1e90ff;
  height: 120%;
}

.category-table {
  @extend .table;
  @extend .table-bordered;
  height: min-content;
}

.risk-heading h2 {
  margin: 0;
}

.risk-profile {
  p {
    margin: 0;
  }

  h3 {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .labels {
    margin-left: 1em;
    margin-right: 1em;
  }

  .risk-block {
    width: 70px;
    height: 70px;
    padding-right: 10px;
    text-align: right;
  }

  .marker {
    padding-top: 0;

    .offset {
      position: relative;
    }

    .arrow {
      display: inline-block;
      font-size: 0;
      line-height: 0;
      width: 0;
      border-top: 13px solid #ffffff;
      border-right: 40px solid #a9a9a9;
      border-bottom: 13px solid #ffffff;
    }

    p {
      display: inline-block;
      padding-left: 5px;
    }

    .indicator {
      position: relative;
      top: -40px;
      left: 115px;
      font-size: 2em;
    }
  }
}

.graph {
  .bar {
    fill: steelblue;
  }

  .axis-y {
    font-size: larger;
  }

  .axis-x {
    font-size: large;

    path {
      display: none;
    }
  }

  .tooltip {
    position: absolute;
    text-align: center;
    font-weight: bolder;
    width: 90px;
    height: 60px;
    padding: 0.8em;
    background: lightsteelblue;
    border: 0;
    border-radius: 8px;
    pointer-events: none;
  }
}

table.evolution {
  max-width: 500px;
  margin-bottom: 30px;
}

.clear-user {
  display: flex;
}

.pre {
  white-space: pre-wrap;
}

@media screen {
  .print-report .manual-page-break {
    display: block !important;
  }
}
